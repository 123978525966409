import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prefooter-areas',
  templateUrl: './prefooter-areas.component.html',
  styleUrls: ['./prefooter-areas.component.scss']
})
export class PrefooterAreasComponent implements OnInit {
  focus;
  focus1;

  constructor() { }

  ngOnInit() {
  }

}
